import service from "../utils/request";

interface UserInfoParam {
  userID: string;
  userName: string;
}
export function apiGetUserInfo(param: UserInfoParam) {
  return service({
    url: "/api/chat/web/role/all",
    method: "get",
    data: param,
  });
}
interface CharActers {
  message?: string;
}
export function apiCharActers(param: CharActers) {
  // return service.get('/api/data', { responseType: 'text/event-stream' })
  return service({
    method: "get",
    url: "/api/chat/characters",
    data: param,
  });
}

interface RoleGroupList {
  data?: string;
}
export function roleGroupList(param: RoleGroupList) {
  // return service.get('/api/data', { responseType: 'text/event-stream' })
  return service({
    method: "get",
    url: "/api/chat/web/role/group/list",
    params: param,
  });
}
export function authLogin() {
  return service({
    method: "post",
    url: "/api/auth/login",
  });
}

export function wxLogin() {
  return service({
    method: "get",
    url: "/api/wx/login/getWechatQtCode",
  });
}
interface WxLogin {
  key?: string;
}

// export function getWxLogin(param: WxLogin) {
//   return service({
//     method: "get",
//     url: "/api/wx/login/login/check/test",
//     params: param,
//   });
// }
interface RoleList {
  groupId?: string | number;
  pageNo?: number;
  pageSize?: number;
  key?: string;
  actionType?: string
}
export function roleList(param: RoleList) {
  return service({
    method: "get",
    url: "/api/chat/web/role/list",
    params: param,
  });
}

interface ChatRequest {
  prompt?: string;
  appCode?: string;
}
export function completion(data: ChatRequest) {
  return service({
    method: "post",
    url: "/api/chat/web/completion",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
interface GetWeChatPublicAccountImageCheck {
  share?: string;
}
export function getWeChatPublicAccountImage(
  param?: GetWeChatPublicAccountImageCheck
) {
  return service({
    method: "get",
    url: "/api/wx/public/login/getWeChatPublicAccountImageBase64",
    params: param,
  });
}

export function loginCheckState(param: WxLogin) {
  return service({
    method: "get",
    url: "/api/wx/public/login/check/state",
    params: param,
  });
}

export function bindCheckState(param: WxLogin) {
  return service({
    method: "get",
    url: "/api/wx/public/login/check/bind/state",
    params: param,
  });
}
interface RoleAddCheck {
  description?: string;
  groupId?: number | null;
  headImageUrl?: string;
  introduction?: string;
  name?: string;
  prompt?: string;
  type?: string;
  isPublic?: string;
  fileName?: string;
}
function roleAdd(param: RoleAddCheck) {
  return service({
    method: "post",
    url: "/api/chat/web/app/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

interface MyRoleCheck {
  pageNo?: number;
  pageSize?: number;
}
function myRole(param: MyRoleCheck) {
  return service({
    method: "get",
    url: "/api/chat/web/app/my",
    params: param,
  });
}

interface RoleDetailCheck {
  code?: string;
}
const roleDetail = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/detail",
    params: param,
  });
};

const roleHeighDetail = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/senior/detail",
    params: param,
  });
};
interface ApiKeyCheck {
  name?: string;
}
const apiKey = async (param?: ApiKeyCheck) => {
  // return await service({
  //   method: "get",
  //   url: "/api/chat/web/app/user/create/api/key",
  // });
  return service({
    method: "post",
    url: "/api/chat/web/app/user/create/api/key",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const apiKeyList = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/create/api/key",
  });
};

interface ChangeRoleSwitchCheck {
  code?: string;
  isDisplayOnAppSquare?: string;
}
const changeRoleSwitch = async (param: ChangeRoleSwitchCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/role/switch",
    params: param,
  });
};

// 修改用户资料
interface UserUpdateCheck {
  nickName?: string;
  headImageUrl?: string;
}
const userUpdate = async (param: UserUpdateCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/user/update",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

interface UserEditUpdateCheck {
  code?: string;
  description?: string;
  displayOnAppSquare?: boolean;
  groupId?: number;
  headImageUrl?: string;
  introduction?: string;
  name?: string;
  prompt?: string;
  textUrl?: string;
  type?: string;
  temperature?: number;
  similarity?: string;
  reply_strategy?: string;
}
const userEditUpdate = async (param: UserEditUpdateCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/update",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getProduct = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/getProduct",
    params: param
  });
};

interface rechargeOrderCheck {
  code?: string;
  goodId?: string;
  payChannel?: string
}
const rechargeOrder = async (param: rechargeOrderCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/user/createOrder",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getBalance = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/get/balance",
  });
};

interface GetUserOrderCheck {
  pageNo?: number;
  pageSize?: number;
}
const getUserOrder = async (param: GetUserOrderCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/getOrder",
    params: param,
  });
};
interface GetRecordDetailCheck {
  orderNo?: string;
}
const getRecordDetail = async (param: GetRecordDetailCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/order/detail",
    params: param,
  });
};

interface queryFormCheck {
  pageNo?: number;
  pageSize?: number;
  appCode?: string;
  endTime?: string;
  model?: string;
  source?: string;
  startTime?: string;
  type?: string
}
const getInvokeRecord = async (param: queryFormCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/getInvokeRecord",
    params: param,
  });
};

const getScoreAll = async (param: queryFormCheck) => {
  return service({
    url: "/api/chat/web/queryInvokeRecordAllUsedScore",
    method: "get",
    params: param,
  });
}
const getInvokeRecordEnum = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/getInvokeRecordQueryParamEnum",
  });
};
interface DeleteFileCheck {
  appCode?: string;
  fileId?: string;
}
const deleteFile = async (param: DeleteFileCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface AddFileCheck {
  code?: string;
  url?: string;
  originFileName?: string;
}
const addFile = async (param: AddFileCheck) => {
  return await service({
    method: "post",
    url: "/api/web/embedding",
    data: JSON.stringify(param),
    timeout: 5 * 6 * 10000,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface appCodeCheck {
  appCode?: string;
}

const deleteRole = async (param: appCodeCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/delete/role",
    params: param,
  });
};

// const weXinLogin = async () => {
//   return await service({
//     method: "get",
//     url: "/api/wx/OAuth/login/getAuthUrl",
//   });
// };
interface getCodeCheck {
  code?: string;
  share?: string;
}
const applyTokenByCode = async (param: getCodeCheck) => {
  return await service({
    method: "get",
    url: "/api/wx/OAuth/login/apply/token",
    params: param,
  });
};

const uploadLinkFile = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
};
const uploadFile = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/cdnUpload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
};
const uploadImage = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/media/upload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
  });
};
const deleteContext = async (param: appCodeCheck) => {
  return await service({
    method: "post",
    url: "/api/web/chat/context/delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface deleteAppKeyCheck {
  id?: string;
}
const deleteAppKey = async (param: deleteAppKeyCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/user/delete/api/key",
    params: param,
  });
};

interface fileListCheck {
  appCode?: string;
  fileId?: string;
  pageNo?: number;
  pageSize?: number;
  keyword?: string;
  itemId?: string;
}
const queryfileList = async (param: fileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface editFileListCheck {
  id?: string;
  appCode?: string;
  fileId?: string;
  text?: string;
  question?: string;
  string?: string;
  onlySearchQuestion?: any
}

const editfileList = async (param: editFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/edit",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface addFileListCheck {
  appCode?: string;
  fileId?: string;
  text?: string;
  question?: string;
  string?: string;
  onlySearchQuestion?: any
}

const addFileList = async (param: addFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/add",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface deleteFileListCheck {
  id?: string;
  appCode?: string;
  fileId?: string;
}
const deleteFileList = async (param: deleteFileListCheck) => {
  return await service({
    method: "post",
    url: "/api/web/data/text/delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface paintForm {
  prompt?: string;
  mode?: string;
  auto_translate?: boolean;
}
const paintMsg = async (param: paintForm) => {
  return await service({
    method: "post",
    url: "/v1/img/web/midjourney/generate",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface imgDetailCheck {
  type?: string;
  img_id?: string;
  index?: boolean;
}

const imgDetail = async (param: imgDetailCheck) => {
  return await service({
    method: "post",
    url: "/v1/img/web/midjourney/operate",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface getMsgCheck {
  task_id?: string;
}
const getImg = async (param: getMsgCheck) => {
  return await service({
    method: "get",
    url: `/v1/img/web/midjourney/tasks/${param.task_id}`,
  });
};

const sendRhyPostInfo = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/voice/stream",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: 'blob',
    data: param,
  });
}

const sendRhygetInfo = async () => {
  return await service({
    method: "get",
    url: "/api/web/tts/stream?text=告诉我你的想法",
    responseType: 'blob',
  });
}

interface appCheck {
  appCode?: string;
}
const getModel = async (param: appCheck) => {
  return await service({
    method: "get",
    url: `/api/web/app/models?appCode=${param.appCode}`,
  });
};

interface loginCheck {
  username: string;
  password: string;
}

const passwordLogin = async (param: loginCheck) => {
  return await service({
    method: "post",
    url: "/api/login",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};
interface verificationCodeCheck {
  phoneNumber: string;
}
const getVerificationCode = async (param: verificationCodeCheck) => {
  return await service({
    method: "get",
    url: '/api/get/verification/code',
    params: param,
  });
};

// 注册
interface registerCheck {
  accountNo: string;
  password: string;
  verificationCode: string;
  shareUserId?: string
}

const registerAccount = async (param: registerCheck) => {
  return await service({
    method: "post",
    url: "/api/register",
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};

interface updatePasswordCheck {
  accountNo?: string,
  newPassword?: string,
  oldPassword?: string,
  verificationCode?: string,
}
const updatePassword = async (param?: updatePasswordCheck) => {
  return service({
    method: "post",
    url: "/api/chat/web/app/user/update/password",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const forgetPwd = async (param?: updatePasswordCheck) => {
  return service({
    method: "post",
    url: "/api/forget/password",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const signDay = async (param: any) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/user/sign/in',
    params: param
  });
};

const weChatPay = async (param: any) => {
  return service({
    method: "post",
    url: "/api/chat/wx/createOrder",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface bindPubilc {
  accountNo: string
}
const bindPubilcPhone = async (param: bindPubilc) => {
  return await service({
    method: "get",
    url: '/api/wx/public/login/getWeChatPublicAccountImageBase64ToBindAccount',
    params: param,
  });
};


const queryBase = async (param?: any) => {
  return service({
    method: "post",
    url: "/api/web/knowledge/base/create",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const benefitsList = async (param: GetUserOrderCheck) => {
  return await service({
    method: "get",
    url: "/api/chat/web/benefits/list",
    params: param,
  });
};

const benefitsAccount = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/benefits/account",
  });
};

const invitationCode = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/get/invitation/code",
  });
};

const getBaseList = async () => {
  return await service({
    method: "get",
    url: "/api/web/knowledge/base/list",
  });
};

interface createBaseCheck {
  name: string,
  desc: string
}

const createBaseApi = async (param: createBaseCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/create",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface parseBaseCheck {
  chunkSize: number,
  url: string
}

const parseBase = async (param: parseBaseCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/file/parse",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const newParseWeb = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/website/parse",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
interface baseListCheck {
  code: string
  page_no: number
  page_size: number
}

const baseList = async (param: baseListCheck) => {
  return await service({
    method: "get",
    url: '/api/web/knowledge/base/file/list',
    params: param,
  });
};

interface filesCheck {
  url: string
  originFileName: string
}
interface embeddingCheck {
  code: string
  chunkSize: number
  files: filesCheck[]
  onlySearchQuestion?: any
}

const embeddingExport = async (param: embeddingCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/file/embedding",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface baseSearchCheck {
  code: string
  query: string
  k: number
}

const baseSearch = async (param: baseSearchCheck) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/search",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface cashoutParam {
  amount: number
  type: string
}

const cashOut = async (param: cashoutParam) => {
  return await service({
    method: "post",
    url: "/api/chat/web/benefits/cashout",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

interface baseRelationCheck {
  appCode: string
  dataCode: string
}

const bindBase = async (param: baseRelationCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/knowledge/base/relation/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const deleteBaseApi = async (param: baseRelationCheck) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/knowledge/base/relation/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAllModel = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/all/ai/model/v2",
  })
};

const baseDetial = async (param: RoleDetailCheck) => {
  return await service({
    method: "get",
    url: '/api/web/knowledge/base/detail',
    params: param,
  });
};

const baseDetele = async (param: RoleDetailCheck) => {
  return await service({
    method: "post",
    url: '/api/web/knowledge/base/delete',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
interface updataBaseCheck {
  code: string
  iconUrl: string
  name: string
  desc: string
}
const updataBase = async (param: updataBaseCheck) => {
  return await service({
    method: "post",
    url: '/api/web/knowledge/base/update',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const roleThumb = async (param: appCheck) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/role/thumb',
    params: param,
  });
};

const roleCollection = async (param: appCheck) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/role/collection',
    params: param,
  });
};

// 插件接口
const allPluginsList = async (param?: any) => {
  return await service({
    method: "post",
    url: `/api/chat/web/app/plugins/template/list${param ? '?scope=' + param.scope : ''}`,
    headers: {
      "Content-Type": "application/json",
    },

  });
};

const pluginsList = async (param: appCodeCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/list',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

interface addPluginsCheck {
  appCode?: string
  args?: string
  config?: string
  enabled?: string
  id?: number
  pluginId?: number
  code?: string
}
const addPlugins = async (param: addPluginsCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/insert',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

interface deletePulginCheck {
  id: string
}

const deletePulgins = async (param: deletePulginCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/delete',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
};

const pluginSwitch = async (param: addPluginsCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/plugins/switch',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

// userKey

const queryUserKey = async () => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/user/query/user/key',

  });
};

interface createUserKeyCheck {
  id?: number
  name?: string
  subType?: string
  type?: string
  userId?: number
  userKey?: string
}
const createUserKey = async (param: createUserKeyCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/user/create/user/key',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const editUserKey = async (param: createUserKeyCheck) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/user/update/user/key',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

interface deleteUserKeyCheck {
  id?: string
}
const deleteUserKey = async (param: deleteUserKeyCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/app/user/delete/user/key",
    params: param,
  });
};

const deletePlugin = async (param: deleteUserKeyCheck) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/plugin/delete",
    params: param,
  });
};

// 获取声音列表
const queryVoice = async () => {
  return await service({
    method: "get",
    url: '/api/web/voice/tone_list',
  });
};
// 获取试听地址

const getVoiceDemo = async (param: any) => {
  return await service({
    method: "get",
    url: '/api/web/voice/demo',
    params: param,
  });
};
interface autoCreateCheck {
  name: string
  description: string
}
const autoCreateImg = async (param: autoCreateCheck) => {
  return await service({
    method: "post",
    url: '/api/web/app/avatar/autoCreate',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
const autoCreate = async (param: autoCreateCheck) => {
  return await service({
    method: "post",
    url: '/api/web/app/autoCreate',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
interface createFileCheck {
  fileName: string
  type: string
  dataCode?: string
}
const createFile = async (param: createFileCheck) => {
  return await service({
    method: "post",
    url: '/api/web/file/create',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

interface exportFilesCheck {
  fileId: string
}
const exportFiles = async (param: exportFilesCheck) => {
  return await service({
    method: "post",
    url: '/api/web/file/export',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const exportFileInvokeRecord = async (param: any) => {
  return await service({
    method: "get",
    url: '/api/chat/web/exportInvokeRecord',
    responseType: 'blob',
    params: param,
  });
};
// 导出知识库未命中
const exportNotHitKnowledgeInvokeRecord = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/exportNotHitKnowledgeInvokeRecord',
    responseType: 'blob',
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
// 导出转人工记录
const exportTransferManualInvokeRecord = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/exportTransferManualInvokeRecord',
    responseType: 'blob',
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
// 导出高频词
const exportHighFrequencyWordRecord = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/export/highFrequencyWordRecord',
    responseType: 'blob',
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
};
interface renameCheck {
  fileName: string
  fileId: string
}
const reName = async (param: renameCheck) => {
  return await service({
    method: "post",
    url: '/api/web/file/rename',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const addCusPlugin = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/plugin/add',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
const editCusPlugin = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/plugin/update',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
// 通道查询
const apiGetChannelList = async () => {
  return service({
    url: "/api/control/channel/list",
    method: "get",
  });
}

const apiGetChannelTag = async (param: any) => {
  return service({
    url: "/api/web/hosting/account/tags",
    method: "get",
    params: param,
  });
}


const apiGetFriendList = async (param: any) => {
  return service({
    url: "/api/web/hosting/tag/friend/list",
    method: "get",
    params: param,
  });
}
const apiGetSessionList = async (param: any) => {
  return service({
    url: "/api/control/msg/session/list",
    method: "get",
    params: param,
  });
}
const apiGetHistoryList = async (param: any) => {
  return service({
    url: "/api/control/msg/session/history",
    method: "get",
    params: param,
  });
}

const apiMessagePush = async (param: any) => {
  return service({
    url: "/api/control/msg/push",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const apiMessageRead = async (param: any) => {
  return service({
    url: "/api/control/msg/read",
    method: "get",
    params: param,
  });
}

const apiGetCompanyInviteUrl = async (param: any) => {
  return service({
    url: "/api/chat/web/company/inviteMember",
    method: "get",
    params: param,
  });
}
const getCompanyMember = async (param: any) => {
  return service({
    url: "/api/chat/web/company/getCompanyMember",
    method: "post",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const addCompany = async (param: any) => {
  return service({
    url: "/api/chat/web/company/add",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const getUserPrivilege = async () => {
  return service({
    url: "/api/chat/web/user/privilege",
    method: "get",
  });
}

const getMemberPrivige = async () => {
  return service({
    url: "/api/chat/web/company/getMemberPrivilege",
    method: "get",
  });
}
const getUseMemberPrivige = async () => {
  return service({
    url: "/api/chat/web/company/getUseMemberPrivilege",
    method: "get",
  });
}

const getUseMemberPrivilegeCost = async () => {
  return service({
    url: "/api/chat/web/company/getUseMemberPrivilegeCost",
    method: "get",
  });
}
const getRolePrivige = async (param: any) => {
  return service({
    url: "/api/chat/web/company/getCompanyRole",
    method: "get",
    params: param,
  });
}

const geCompanyIvite = async (param: any) => {
  return service({
    url: "/api/chat/web/company/getCompanyByInviteParam",
    method: "get",
    params: param,
  })
}
interface botChatParam {
  clientId?: string;
}
const getMetaData = async (param: botChatParam) => {
  return await service({
    method: "get",
    url: "/api/web/integration/metadata",
    params: param,
  });
};
// 获取web集成list
const getWebAppList = async (param: any) => {
  return service({
    url: "/api/chat/web/integration/list",
    method: "get",
    params: param,
  });
}
// web集成创建
const createWebapp = async (param: any) => {
  return service({
    url: "/api/chat/web/integration/create",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

// 获取web集成详情
const getWebAppDetail = async (param: any) => {
  return service({
    url: "/api/chat/web/integration/detail",
    method: "get",
    params: param,
  });
}

const agreeEnterCompany = async (param: any) => {
  return service({
    url: "/api/chat/web/company/agreeEnterCompany",
    method: "get",
    params: param,
  });
}
// web集成更新
const updateWebapp = async (param: any) => {
  return service({
    url: "/api/chat/web/integration/update",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

// web集成删除
const deleteWebapp = async (param: any) => {
  return service({
    url: "/api/chat/web/integration/delete",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const getCompanyDetail = async () => {
  return service({
    url: "/api/chat/web/company/detail",
    method: "get",
  });
}

const editClient = async (param: any) => {
  return service({
    url: "/api/control/channel/update",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const deleteClientApi = async (param: any) => {
  return service({
    url: "/api/control/channel/delete",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const updataQrUrl = async (param: any) => {
  return service({
    url: "/api/control/channel/status",
    method: "get",
    params: param,
  });
}
const getMediaList = async (param: any) => {
  return service({
    url: "/api/chat/web/media/list",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
}
const deleteMedia = async (param: any) => {
  return await service({
    method: "delete",
    url: "/api/chat/web/media/delete",
    params: param,
  });
};

const configDetail = async (param: any) => {
  return await service({
    url: "/api/control/channel/config/detail",
    method: "get",
    params: param,
  });
};

const configSave = async (param: any) => {
  return await service({
    url: "/api/control/channel/config/save",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
const getAccountList = async (param: any) => {
  return await service({
    url: "/api/web/hosting/account/list",
    method: "get",
    params: param,
  });
};

const getAccountAppDetail = async (param: any) => {
  return await service({
    url: "/api/web/hosting/config/private/info",
    method: "get",
    params: param,
  });
};
const getGroupList = async (param: any) => {
  return await service({
    url: "/api/web/hosting/group/list",
    method: "get",
    params: param,
  });
};

const onHostingPost = async (param: any) => {
  return await service({
    url: "/api/web/hosting/account/on",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const offHostingPost = async (param: any) => {
  return await service({
    url: "/api/web/hosting/account/off",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const onHostingGroupPost = async (param: any) => {
  return await service({
    url: "/api/web/hosting/group/on",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const offHostingGroupPost = async (param: any) => {
  return await service({
    url: "/api/web/hosting/group/off",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const updateAccountAppCode = async (param: any) => {
  return await service({
    url: "/api/web/hosting/account/app_code",
    method: 'post',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  })
}
const updateGroupAppCode = async (param: any) => {
  return await service({
    url: "/api/web/hosting/group/app_code",
    method: 'post',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  })
}
const synWaitOpen = async (param: any) => {
  return await service({
    url: "/api/web/hosting/group/syn_wait_open",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const addAppLimitConfig = async (param: any) => {
  return await service({
    url: "/api/chat/web/rate/limit/add",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const getAdvancedConfigQuerg = async (param: any) => {
  return await service({
    url: "/api/chat/web/app/role/advanced/config/detail",
    method: "get",
    params: param,
  });
}

const getCompanyNotifyMember = async () => {
  return await service({
    url: "/api/chat/web/company/getCompanyNotifyMember",
    method: "get",
  });
}

const saveAdvancedConfig = async (param: any) => {
  return await service({
    url: "/api/chat/web/app/role/advanced/config/update",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const deleteAppLimitConfig = async (param: any) => {
  return await service({
    url: "/api/chat/web/rate/limit/delete",
    method: "delete",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
}
const queryAppLimitConfig = async (param: any) => {
  return await service({
    url: "/api/chat/web/rate/limit/query",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
}
const updateAppLimitConfig = async (param: any) => {
  return await service({
    url: "/api/chat/web/rate/limit/update",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const changeUserService = async (param: any) => {
  return await service({
    url: "/api/control/channel/sender/serviceMode/update",
    method: "get",
    params: param,
  });
}
const getSessionList = async (param: any) => {
  return await service({
    url: "/api/control/msg/session/manual/list",
    method: "get",
    params: param,
  });
}

const getHostingArea = async () => {
  return await service({
    url: "/api/web/hosting/area",
    method: "get",
  });
}

const getLoginQrcode = async (param: any) => {
  return await service({
    url: "/api/web/hosting/login/qr_code",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const getHostingStatus = async (param: any) => {
  return await service({
    url: "/api/web/hosting/login/status",
    method: "get",
    params: param,
  });
}
const getWorkFlowDetail = async (param: any) => {
  return await service({
    url: "/api/web/workflow/detail",
    method: "get",
    params: param,
  });
}

const getHostingOffline = async (param: any) => {
  return await service({
    url: "/api/web/hosting/login/submit_verify_code",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const saveWorkFlowDetail = async (param: any) => {
  return await service({
    url: "/api/web/workflow/save",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const clientConfig = async (param: any) => {
  return await service({
    url: "/api/control/channel/create/client/config",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const createWorkFlowDetail = async (param: any) => {
  return await service({
    url: "/api/web/workflow/create",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const deleteWorkFlowDetail = async (param: any) => {
  return await service({
    url: "/api/web/workflow/delete",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const workflowRun = async (param: any) => {
  return await service({
    url: "/api/web/workflow/run",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const worFlowList = async () => {
  return await service({
    url: "/api/web/workflow/list",
    method: "get",
  });
}
const getCronGenerate = async (param: any) => {
  return await service({
    url: "/api/web/workflow/cron/generate",
    method: "get",
    params: param,
  });
}

const runCronGenerate = async (param: any) => {
  return await service({
    url: "/api/web/workflow/cron/run",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const getFriendList = async (param: any) => {
  return await service({
    url: "/api/web/hosting/friend/list",
    method: "get",
    params: param,
  });
}

const editFlowDetail = async (param: any) => {
  return await service({
    url: "/api/web/workflow/info/edit",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const getTimeRun = async (param: any) => {
  return await service({
    url: "/api/web/workflow/scheduler/detail",
    method: "get",
    params: param,
  });
}
const saveTimeRun = async (param: any) => {
  return await service({
    url: "/api/web/workflow/scheduler/save",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const getByScene = async (param: any) => {
  return await service({
    url: "/api/chat/web/threshold/config/getByScene",
    method: "get",
    params: param,
  });
}

const addByScene = async (param: any) => {
  return await service({
    url: "/api/chat/web/threshold/config/add",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const updateByScene = async (param: any) => {
  return await service({
    url: "/api/chat/web/threshold/config/update",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const hitSwitchByScene = async (param: any) => {
  return await service({
    url: "/api/chat/web/threshold/config/hitSwitch",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    data: param,
  });
}
const getNoticeList = async (param: any) => {
  return await service({
    url: "/api/chat/web/announcement/list",
    method: "get",
    params: param,
  });
}
const postReadNotice = async (param: any) => {
  return await service({
    url: "/api/chat/web/announcement/read",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}
const postReadNoticeAll = async () => {
  return await service({
    url: "/api/chat/web/announcement/read/all",
    method: "get",
  });
}

const getStatistics = async () => {
  return await service({
    url: "/api/chat/web/announcement/get/announcement/statistics",
    method: "get",
  });
}

interface tableColums {
  fileId: string,
  columns?: any
}
const editTableColums = async (param: tableColums) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/table/columns/edit",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const tableViewAddColums = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/data/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const tableViewEditColums = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/data/edit",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const submitInvite = async (param: any) => {
  return await service({
    method: "post",
    url: " /api/chat/web/invite/submit",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getFindByFileId = async (param: any) => {
  return await service({
    url: "/api/web/knowledge/base/findByFileId",
    method: "get",
    params: param,
  });
}
// 用户数量统计
const getUseUserCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/useUserCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 对话渠道数量统计
const getRecordChannelCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/recordChannelCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 对话数量统计
const getRecordCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/recordCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 对话数量统计(未命中知识库)
const getRecordNotHitKnowledgeBaseCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/recordNotHitKnowledgeBaseCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 积分消耗数量统计
const getScoreCostCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/scoreCostCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 转人工数量统计
const getTransferManualCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/transferManualCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 应用升级
const upgradeApp = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/app/upgrade",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};


// 私聊配置保存
const privateSetting = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/hosting/config/private/save",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 群聊配置保存
const groupSetting = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/hosting/config/group/save",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 单个群聊配置删除
const delectGroupSetting = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/hosting/config/group/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 聊天设置详情查询
const getGroupSetting = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/hosting/config/detail",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 团队空间-公司信息编辑
const editCompanyInfo = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/update",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 团队空间-移出成员
const deleteCompanyMember = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/deleteCompanyMember",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 团队空间-退出团队空间
const exitCompany = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/company/exitCompany",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 获取用户未命中知识库调用记录

const getNotKnowBase = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/getNotHitKnowledgeInvokeRecord",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 对话活跃度统计
const getRecordActiveCount = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/recordActiveCount",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 词云
const getHighFrequencyWord = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/data/board/get/highFrequencyWord",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 词云详情
const getHighFrequencyWordDetail = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/get/highFrequencyWordRecord",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 获取转人工的调用记录
const getRecordInvokeRecord = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/getTransferManualInvokeRecord",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getHistoryLogin = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/hosting/login/last_info",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 注销群聊接口
const groupCancel = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/hosting/group/cancel",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 插件分类
const getGroupType = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/plugin/group/list",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 插件详情
const getGroupDetail = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/plugin/detail",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 客户列表

const getCustomerList = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/list",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 标签列表
const getAllCustomerTagList = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/tag/all",

  });
};
// 获取单个用户标签
const getCustomerTagList = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/tag/self",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getTagList = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/tag/list",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 添加标签
const addTagList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/customer/tag/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 删除标签
const deleteTagList = async (param: any) => {
  return await service({
    method: "post",
    url: `/api/chat/web/customer/tag/delete/${param.tagId}`,
  });
}
// 修改标签
const updateTagList = async (param: any) => {
  return await service({
    method: "post",
    url: `/api/chat/web/customer/tag/update/${param.tagId}`,
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 批量打标签
const batchTag = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/customer/tag/mapping/batch',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// Ai批量打标签
const batchAiTag = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/customer/tag/mapping/ai',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 刷新token
const getUserDetail = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/app/user/get/detail",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//成员设置角色
const setCompanyMemberRole = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/setCompanyMemberRole",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//新增角色
const addCompanyRole = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/addCompanyRole",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 编辑角色
const modifyCompanyRole = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/modifyCompanyRole",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 查询角色的功能权限资源
const queryCompanyRoleFunctionResource = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/company/queryCompanyRoleFunctionResource",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 修改角色功能权限资源
const modifyCompanyRoleFunctionResource = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/modifyCompanyRoleFunctionResource",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//查询角色数据权限资源
const queryCompanyRoleDataResource = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/company/queryCompanyRoleDataResource",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//查询角色数据权限资源
const modifyCompanyRoleDataResource = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/modifyCompanyRoleDataResource",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 删除角色
const deleteCompanyRole = async (param: any) => {
  return await service({
    url: "/api/chat/web/company/deleteCompanyRole",
    method: "delete",
    data: param,
  });
};
// 获取标签分组
const getCustomerTagGroup = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/tag/group/list",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 获取所有标签分组
const getAllCustomerTagGroup = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/customer/tag/group/all"
  });
};

// 创建标签分组
const createTagGroup = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/customer/tag/group/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 删除标签分组
const deleteTagGroupList = async (param: any) => {
  return await service({
    method: "post",
    url: `/api/chat/web/customer/tag/group/delete/${param.tagGroupId}`,
  });
}
// 修改标签分组
const updateTagGroupList = async (param: any) => {
  return await service({
    method: "post",
    url: `/api/chat/web/customer/tag/group/update/${param.tagGroupId}`,
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 同步客户
const syncCustomer = async () => {
  return await service({
    method: "post",
    url: '/api/chat/web/customer/sync',
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 工作流发布到广场
const workflowPublish = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/workflow/publish",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 工作流详情查询

const getChatWorkFlowDetail = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/workflow/square/detail",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// const runWebWorkFlow = async (param: any) => {
//   return await service({
//     method: "get",
//     url: "/api/web/workflow/square/detail",
//     params: param,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// 取消工作流发布

const cancelWorkFlow = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/workflow/publish/cancel",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 分配积分
const postAllocatepPoints = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/company/companyBalanceManage",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getAlllocatepPoints = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/company/sumCompanyBalance"
  });
};
const getAllBenefits = async () => {
  return await service({
    method: "get",
    url: "/api/chat/web/benefits/getTotalBenefitsScore"
  });
}
const parseFeishu = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/feishu/parse",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const importFileFeishu = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/base/file/embedding",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const saveFeishuChnneld = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/knowledge/feishu/authorization/save",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getFeishuChnneld = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/knowledge/feishu/authorization/info"
  });
};

const repeatMediaName = async (param: any) => {
  return await service({
    method: 'post',
    url: '/api/chat/web/media/change_name',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  })
}
// 数据库
const getAllDataBase = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/list",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// 创建数据库
const createDatabase = async (param: any) => {
  return await service({
    method: 'post',
    url: '/api/web/database/create',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  })
}
// 查询表
const queryDatabaseTable = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/tables",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//查询表字段
const queryDatabaseTableschema = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/table_schema",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// 修改数据表
const editDatbaseTableschema = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/edit_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//

const queryOnlineDatabase = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/online/tables",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const queryDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const saveOnlineDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/online/table/save",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const saveBuiltinFormDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/create_table",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getDatabaseDetail = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database",
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const editTableDes = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/update_comment",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const importTableData = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/import_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const templateTableData = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/builtin/template_file",
    // params: param,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    responseType: 'blob',
    params: param,
  });
};
const deleteTableData = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/delete_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const addTableColumn = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/add_field",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const deleteTableColumn = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/delete_field",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const addTableData = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/add_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const editTableData = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/edit_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const editDataBaseDetail = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/update",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const appBindDatabase = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/relation/add",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const deleteData = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const deleteDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/delete",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const parseDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/pre_parse",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const importParseDataTable = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/builtin/fast_import_data",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const editDataTableComment = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/database/table/update_field_comment",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 插件解析
const parseDebug = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/web/plugin/debug",
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const exportTableComment = async (param: any) => {
  return await service({
    method: "get",
    url: "/api/web/database/table/export",
    responseType: 'blob',
    params: param,
  });
}

const voiceAppWorkflow = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/web/voice/read',
    data: JSON.stringify(param),
    headers: {
      "Content-Type": "application/json",
    },
    responseType: 'blob',
  })
}

const queryTemplateList = async (param: any) => {
  return await service({
    url: '/api/chat/web/role/template/list',
    method: "get",
    params: param,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // data: JSON.stringify(param),
  })
}
const templateGroupList = async () => {
  return await service({
    method: "get",
    url: '/api/chat/web/role/template/group/list',
  })
}
const templateDetail = async (param: any) => {
  return await service({
    method: "get",
    url: '/api/chat/web/app/template/detail',
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

const copyTemplateApp = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/template/copy',
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

const workflowSystem = async (param: any) => {
  return await service({
    method: "get",
    url: '/api/web/workflow/system/args',
  })
}

const copyApp = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/app/copy',
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const copyFlowWorkApi = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/app/workflow/copy',
    params: param,
    headers: {
      "Content-Type": "application/json",
    },
  })
}

const exportChatList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/control/msg/session/history/export",
    responseType: 'blob',
    headers: {
      "Content-Type": "application/json",
    },
    data: param,
  });
}
const deleteMediaResourceList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/media/delete/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const supplierTypeList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/model/supplier/type/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
}

const modelManageList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/model/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};

const modelSupplierSave = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/model/supplier/save',
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
const modelSupplierDetail = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/model/supplier/detail',
    headers: {
      "Content-Type": "application/json",
    },
    params: param,
  });
};

const modelManageDelete = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/model/delete',
    headers: {
      "Content-Type": "application/json",
    },
    params: param,
  });
};
const modelSupplierDelete = async (param: any) => {
  return await service({
    method: "post",
    url: '/api/chat/web/model/supplier/delete',
    headers: {
      "Content-Type": "application/json",
    },
    params: param,
  });
};

const getModelSupplierTypeAllList = async (param: any) => {
  return await service({
    method: "post",
    url: "/api/chat/web/model/supplier/type/all/list",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(param),
  });
};
export {
  roleAdd,
  myRole,
  roleDetail,
  apiKey,
  apiKeyList,
  changeRoleSwitch,
  userUpdate,
  getProduct,
  rechargeOrder,
  getBalance,
  getUserOrder,
  userEditUpdate,
  getRecordDetail,
  getInvokeRecord,
  roleHeighDetail,
  deleteFile,
  addFile,
  deleteRole,
  applyTokenByCode,
  uploadLinkFile,
  uploadImage,
  deleteContext,
  deleteAppKey,
  queryfileList,
  editfileList,
  addFileList,
  deleteFileList,
  paintMsg,
  getImg,
  imgDetail,
  getModel,
  passwordLogin,
  getVerificationCode,
  registerAccount,
  updatePassword,
  signDay,
  weChatPay,
  bindPubilcPhone,
  queryBase,
  benefitsList,
  benefitsAccount,
  invitationCode,
  getBaseList,
  createBaseApi,
  parseBase,
  baseList,
  embeddingExport,
  baseSearch,
  cashOut,
  bindBase,
  deleteBaseApi,
  getAllModel,
  baseDetial,
  updataBase,
  baseDetele,
  roleThumb,
  roleCollection,
  allPluginsList,
  pluginsList,
  addPlugins,
  deletePulgins,
  pluginSwitch,
  queryUserKey,
  createUserKey,
  editUserKey,
  deleteUserKey,
  sendRhyPostInfo,
  sendRhygetInfo,
  forgetPwd,
  autoCreate,
  autoCreateImg,
  createFile,
  exportFiles,
  getInvokeRecordEnum,
  reName,
  getScoreAll,
  addCusPlugin,
  deletePlugin,
  editCusPlugin,
  apiGetChannelList,
  apiGetSessionList,
  apiGetHistoryList,
  apiMessagePush,
  apiMessageRead,
  getMetaData,
  getWebAppList,
  createWebapp,
  getWebAppDetail,
  updateWebapp,
  deleteWebapp,
  apiGetCompanyInviteUrl,
  getUserPrivilege,
  getCompanyMember,
  getMemberPrivige,
  getRolePrivige,
  geCompanyIvite,
  agreeEnterCompany,
  getUseMemberPrivige,
  getUseMemberPrivilegeCost,
  addCompany,
  getCompanyDetail,
  editClient,
  deleteClientApi,
  updataQrUrl,
  getMediaList,
  deleteMedia,
  configDetail,
  configSave,
  getAccountList,
  getGroupList,
  onHostingPost,
  offHostingPost,
  onHostingGroupPost,
  offHostingGroupPost,
  updateAccountAppCode,
  updateGroupAppCode,
  synWaitOpen,
  addAppLimitConfig,
  deleteAppLimitConfig,
  queryAppLimitConfig,
  updateAppLimitConfig,
  getAdvancedConfigQuerg,
  getCompanyNotifyMember,
  saveAdvancedConfig,
  changeUserService,
  getSessionList,
  exportFileInvokeRecord,
  getHostingArea,
  getLoginQrcode,
  getHostingStatus,
  getHostingOffline,
  clientConfig,
  getWorkFlowDetail,
  saveWorkFlowDetail,
  createWorkFlowDetail,
  workflowRun,
  worFlowList,
  deleteWorkFlowDetail,
  getCronGenerate,
  runCronGenerate,
  getFriendList,
  editFlowDetail,
  getTimeRun,
  saveTimeRun,
  getByScene,
  addByScene,
  updateByScene,
  hitSwitchByScene,
  getNoticeList,
  postReadNotice,
  postReadNoticeAll,
  getStatistics,
  editTableColums,
  tableViewAddColums,
  tableViewEditColums,
  submitInvite,
  getUseUserCount,
  getRecordChannelCount,
  getRecordCount,
  getRecordNotHitKnowledgeBaseCount,
  getScoreCostCount,
  getTransferManualCount,
  getFindByFileId,
  upgradeApp,
  privateSetting,
  groupSetting,
  delectGroupSetting,
  getGroupSetting,
  editCompanyInfo,
  deleteCompanyMember,
  exitCompany,
  getNotKnowBase,
  getRecordActiveCount,
  getRecordInvokeRecord,
  getHistoryLogin,
  groupCancel,
  getHighFrequencyWord,
  getHighFrequencyWordDetail,
  exportNotHitKnowledgeInvokeRecord,
  exportTransferManualInvokeRecord,
  exportHighFrequencyWordRecord,
  getGroupType,
  getGroupDetail,
  newParseWeb,
  getCustomerList,
  getUserDetail,
  getAllCustomerTagList,
  getCustomerTagList,
  getTagList,
  updateTagList,
  addTagList,
  deleteTagList,
  batchTag,
  setCompanyMemberRole,
  addCompanyRole,
  modifyCompanyRole,
  deleteCompanyRole,
  queryCompanyRoleFunctionResource,
  modifyCompanyRoleFunctionResource,
  queryCompanyRoleDataResource,
  modifyCompanyRoleDataResource,
  getCustomerTagGroup,
  getAllCustomerTagGroup,
  createTagGroup,
  deleteTagGroupList,
  updateTagGroupList,
  syncCustomer,
  batchAiTag,
  workflowPublish,
  getChatWorkFlowDetail,
  cancelWorkFlow,
  getAccountAppDetail,
  postAllocatepPoints,
  getAlllocatepPoints,
  getAllBenefits,
  parseFeishu,
  importFileFeishu,
  saveFeishuChnneld,
  getFeishuChnneld,
  repeatMediaName,
  uploadFile,
  getAllDataBase,
  createDatabase,
  queryDatabaseTable,
  queryDatabaseTableschema,
  editDatbaseTableschema,
  queryOnlineDatabase,
  queryDataTable,
  saveOnlineDataTable,
  saveBuiltinFormDataTable,
  getDatabaseDetail,
  editTableDes,
  importTableData,
  templateTableData,
  deleteTableData,
  addTableColumn,
  addTableData,
  editTableData,
  editDataBaseDetail,
  appBindDatabase,
  deleteData,
  deleteDataTable,
  parseDataTable,
  importParseDataTable,
  editDataTableComment,
  queryVoice,
  getVoiceDemo,
  parseDebug,
  exportTableComment,
  voiceAppWorkflow,
  queryTemplateList,
  templateGroupList,
  templateDetail,
  copyTemplateApp,
  workflowSystem,
  copyApp,
  copyFlowWorkApi,
  deleteTableColumn,
  exportChatList,
  apiGetChannelTag,
  apiGetFriendList,
  deleteMediaResourceList,
  supplierTypeList,
  modelManageList,
  modelSupplierSave,
  modelSupplierDetail,
  modelManageDelete,
  modelSupplierDelete,
  getModelSupplierTypeAllList
}
